import * as React from 'react'
import featureReducer from './reducer'
import {initialState} from './initialState'

const FeatureContext = React.createContext()

function FeatureProvider({children}) {
  const [state, dispatch] = React.useReducer(featureReducer, initialState)
  const value = {state, dispatch}
  return <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
}
function useFeature() {
  const context = React.useContext(FeatureContext)
  if (context === undefined) {
    throw new Error('useFeature must be used within a Provider')
  }
  return context
}
const MODULE_ORDER = "MODULE_ORDER"
const DISABLE_PLUS_DISH_BUTTON = "DISABLE_PLUS_DISH_BUTTON"
const COLOR = "COLOR"
const STYLED_BOTTOM_BUTTON = "STYLED_BOTTOM_BUTTON"
const PRODUCT_ELEVATION = "PRODUCT_ELEVATION"
const AVATAR_ROUND = "AVATAR_ROUND"
const ORDER_ITEM_NEW = "ORDER_ITEM_NEW"
const INFO = "INFO"
const LANGUAGE = "LANGUAGE"

export {FeatureProvider, useFeature, LANGUAGE, MODULE_ORDER, DISABLE_PLUS_DISH_BUTTON, COLOR, STYLED_BOTTOM_BUTTON, PRODUCT_ELEVATION, AVATAR_ROUND, ORDER_ITEM_NEW, INFO}
