// routes
import Router from './routes'; 
// theme
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------
import {useContext} from './context/general/context'

export default function App() {
  

  const {state} = useContext()

  return (<>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
      </>
  );
}
