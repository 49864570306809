import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'
// ----------------------------------------------------------------------

export default function AddFeatureForm() {
    const {state} = useContext()
    const {get, post, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();
    

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Name required'),
        description: Yup.string().min(1, 'Too Short!').max(250, 'Too Long!').required('Description required'),
        key: Yup.string().min(1, 'Too Short!').max(250, 'Too Long!').required('Key required'),
        payloadType: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('PayloadType required'),
        restaurantId: Yup.string(),
    });
    
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            key: '',
            payloadType: '',
            restaurantId: state.currentRestaurantId,
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            await post(`features`, {...values})
            navigate('/dashboard/manage-features', { replace: true });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Name"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                        <TextField
                            fullWidth
                            label="Key"
                            {...getFieldProps('key')}
                            error={Boolean(touched.key && errors.key)}
                            helperText={touched.key && errors.key}
                        />
                   
                    </Stack>

                    <TextField
                            fullWidth
                            label="PayloadType"
                            {...getFieldProps('payloadType')}
                            error={Boolean(touched.payloadType && errors.payloadType)}
                            helperText={touched.payloadType && errors.payloadType}
                        />
                    
                    <TextField
                            fullWidth
                            multiline
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                        />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Adauga
          </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
