import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ColorPicker from '../utils/ColorPicker';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import FeatureListHead from '../components/_dashboard/features/FeatureListHead';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { backendUrl } from '../index'
import {
  Box,
  Input,
  IconButton,
  CircularProgress
} from '@mui/material';
import { useContext } from '../context/general/context'
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Nume', label: 'Nume', alignRight: false },
  { id: 'Descriere', label: 'Descriere', alignRight: false },
  { id: 'Activ', label: 'Activ', alignRight: false },
  { id: 'Optiuni', label: 'Optiuni', alignRight: false },
  { id: 'Activabil', label: 'Activabil', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: '' }
];


function EditableCell({ id, initialValue }) {

  const [value, setValue] = useState(initialValue)
  const [isEditMode, setIsEditMode] = useState(false)

  const [loading, setLoading] = useState(false)

  const { state, dispatch } = useContext()

  const onChange = (event) => {
    setIsEditMode(true)
    setValue(event.target.value)
  }

  async function onSave() {
    setLoading(true)

    await fetch(backendUrl + '/restaurants-features/' + id, {
      method: 'PUT', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(
        { payload: value }
      )
    })

    setLoading(false)
    setIsEditMode(false)

    dispatch({ type: "GLOBAL_UPDATE" })
  }

  return (
    <TableCell align="left">
      <Input
        // disableUnderline
        value={value}
        // name={column}
        onChange={e => onChange(e)}
      />
      {isEditMode &&
        <IconButton
          sx={{ color: "#00c853" }}
          aria-label="done"
          onClick={onSave}
          disabled={loading}
        >
          {loading ? <CircularProgress /> : <DoneOutlineIcon />}
        </IconButton>
      }
    </TableCell>
  )
}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const IOSSwitchButton = ({ id, value }) => {
  const { state, dispatch } = useContext()

  const [loading, setLoading] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)


  async function onChange() {
    setLoading(true)
    console.log(value)
    await fetch(backendUrl + '/restaurants-features/' + id + '/active', {
      method: 'PUT', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(
        { active: !value }
      )
    })

    dispatch({ type: "GLOBAL_UPDATE" })
    setCurrentValue(!value)
    setLoading(false)

  }

  return (
    <>
      {loading ? <CircularProgress />
        :
        <IOSSwitch
          onChange={onChange}
          sx={{ m: 1 }} checked={currentValue} />
      }

    </>
  )

}
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Features() {
  const [USERLIST, setUserList] = useState()
  const { state, dispatch } = useContext()
  const [features, setFeatures] = useState()
  const [restaurantFeatures, setRestaurantFeatures] = useState()



  useEffect(async () => {
    if (state.currentRestaurantId) {
      const response = await fetch(backendUrl + '/features')
      const json = await response.json()
      if (response.ok) {
        setFeatures(json)
      }
    } else {
      setFeatures([])
    }

  }, [state.currentRestaurantId, state.globalUpdate])

  useEffect(async () => {
    if (state.currentRestaurantId) {
      const response = await fetch(backendUrl + '/restaurants/' + state.currentRestaurantId + '/features')
      const json = await response.json()
      if (response.ok) {
        const map = {}
        json.forEach(feature => map[feature.featureId] = feature)
        setRestaurantFeatures(map)
      }
    } else {
      setRestaurantFeatures({})
    }

  }, [state.currentRestaurantId, state.globalUpdate])

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [trigger, setTrigger] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = features.map((n) => n.name);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - features.length) : 0;
  if (!features) return <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh"
  >
      <CircularProgress size={70} />
</Box>
  if (!restaurantFeatures) return <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh"
  >
      <CircularProgress size={70} />
</Box>


  const filteredFeatures = applySortFilter(features, getComparator(order, orderBy), filterName);

  const isFeatureNotFound = filteredFeatures.length === 0;


  return (
    <Page title="User | Reach">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Setari
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table sx={{ marginBottom: trigger ? "35vh" : "0"}} >
              {/* <Table> */}
                <FeatureListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={features.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody >
                  {filteredFeatures
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, description, payloadType } = row;

                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                          <TableCell component="th" scope="row" >
                            {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                            {/* </Stack> */}
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            <Typography  noWrap>
                              {description}
                            </Typography>
                          </TableCell>
                          {restaurantFeatures.hasOwnProperty(id) ? <TableCell align="left">
                            <IOSSwitchButton value={restaurantFeatures[id].active} id={restaurantFeatures[id].id}  />
                          </TableCell>
                            :
                            <TableCell align="left"></TableCell>
                          }
                          {restaurantFeatures.hasOwnProperty(id) ? 
                          // <EditableCell id={restaurantFeatures[id].id} initialValue={restaurantFeatures[id].payload}  ></EditableCell> 
                          
                          (payloadType === "COLOR" &&
                            (<TableCell zIndex={1000} align="left">
                                <ColorPicker setTrigger={setTrigger} id={restaurantFeatures[id].id} existingColor={restaurantFeatures[id].payload || '#000'} />
                            </TableCell>)) ||

                          (payloadType === "STRING" && 
                          (<EditableCell id={restaurantFeatures[id].id} initialValue={restaurantFeatures[id].payload}  >

                          </EditableCell>)) ||
                          
                          payloadType === "NONE" && <TableCell align="left"></TableCell>

                          :
                            <TableCell align="left"></TableCell>
                          }

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={restaurantFeatures.hasOwnProperty(id) ? 'success' : 'error'}
                            >
                              {restaurantFeatures.hasOwnProperty(id) ? 'Disponibil' : 'Indisponibil'}
                            </Label>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isFeatureNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage="Randuri per pagina"
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={features.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{zIndex:-1000}}
          />
        </Card>
      </Container>
    </Page>
  );
}
