import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import uploadTaskPromise, { backendUrl } from '../../../index'
import { useContext } from '../../../context/general/context'

// ----------------------------------------------------------------------
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// ----------------------------------------------------------------------

import Upload from './uploader/Upload'
import MultipleSelect from './MultipleSelect';

export default function EditProductForm({ product }) {
    const { get, post, put, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();

    const { state, dispatch } = useContext()
    const language = state.language

    const [image, setImage] = useState();

    const [categories, setCategories] = useState()
    const [category, setCategory] = useState(product.categoryId)

    const [newTags, setNewTags] = useState([])
    const [tag, setTag] = useState([])
    
   // const { state, dispatch } = useCart()


  

    const handleChange = (event) => {
        console.log(event.target.value)
        setCategory(event.target.value);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeTags = (event) => {
        setTag(event.target.value);
    };

    async function uploadImage() {
        
        return await uploadTaskPromise( image, state.currentRestaurantId)

    }

    useEffect(async () => {
        if (state.currentRestaurantId !== "") {
            const response = await fetch(backendUrl + `/restaurants/${state.currentRestaurantId}/categories` + (language === 'ro' ? '' : `?lang=${language}`))
            const json = await response.json()
            if (response.ok) {
                setCategories(json)
            }
        } else {
            setCategories([])
        }

    }, [state.currentRestaurantId])

    useEffect(async () => {
        const response = await fetch(backendUrl + `/tags`)
        const json = await response.json()
        if (response.ok) {
            setNewTags(json)
        }

    }, [state.currentRestaurantId])

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Numele este prea scurt!').max(255, 'Numele este prea lung!').required('Numele este obligatoriu!'),
        categoryId: Yup.string().required('categoryId este obligatoriu!'),
        restaurantId: Yup.string(),
        price: Yup.number().required('Pretul este obligatoriu!'),
        priceDetails: Yup.string().nullable(),
        discount: Yup.number().min(0, 'Minim 0').max(100, "Maxim 100").nullable(),
        description: Yup.string().min(2, 'Descrierea este prea scurta!').max(2000, 'Descrierea este prea lunga!').required('Descrierea este obligatorie!'),
        imgUrl: Yup.string().required('imgUrl este obligatoriu!'),
        tags: Yup.string()
    });

    const { id, name, imgUrl, price, priceDetails, discount, categoryId, description, tags } = product;

    const [formState, setFormState] = useState({
        items: tags.map(tag => tag.id)
    });

    const formik = useFormik({
        initialValues: {
            name: name,
            categoryId: categoryId,
            restaurantId: state.currentRestaurantId,
            price: price,
            priceDetails: priceDetails,
            discount: discount,
            description: description,
            imgUrl: imgUrl,
            tags: JSON.stringify(tags)
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            const newImageUrl = image ? await uploadImage() : imgUrl
            console.log(newImageUrl)
            await put(`products/${id}` + (language === 'ro' ? '' : `?lang=${language}`), { ...values, imgUrl: newImageUrl, categoryId: category || categories[0].id, tags: formState.items })
            navigate('/dashboard/products', { replace: true });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    // if (!categories) return "Loading..."
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nume"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={category ? category : ''}
                                label="Category"
                                onChange={handleChange}
                            >
                                {categories?.map(
                                    category =>
                                        <MenuItem value={category.id}>{category.name}</MenuItem>
                                )}


                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                        <TextField
                            fullWidth
                            label="Pret"
                            {...getFieldProps('price')}
                            error={Boolean(touched.price && errors.price)}
                            helperText={touched.price && errors.price && 'Introduceti un numar valid!'}
                        />

                        <MultipleSelect formState={formState} setFormState={setFormState} items={newTags} label="Etichete"></MultipleSelect>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                        <TextField
                            fullWidth
                            label="Detalii pret"
                            {...getFieldProps('priceDetails')}
                            error={Boolean(touched.priceDetails && errors.priceDetails)}
                            helperText={touched.priceDetails && errors.priceDetails && 'Introduceti un numar valid!'}
                        />

                        <TextField
                            fullWidth
                            label="Discount"
                            {...getFieldProps('discount')}
                            error={Boolean(touched.discount && errors.discount)}
                            helperText={touched.discount && errors.discount && 'Introduceti un numar intre 0 si 100!'}
                        />
                    </Stack>

                    <TextField
                        multiline
                        fullWidth
                        label="Descriere"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                    />

                    <Upload setImage={setImage} text="Schimba imagine" />
                    {image ?
                        <Avatar
                            src={URL.createObjectURL(image)}
                            variant="square"
                            sx={{ width: "10%", height: "100%" }}
                        >
                        </Avatar>
                        :
                        <Avatar
                            src={imgUrl}
                            variant="square"
                            sx={{ width: "10%", height: "100%" }}
                        >
                        </Avatar>
                    }



                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Salveaza
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
