import React, { useState } from 'react'

import { Button, Typography } from '@mui/material';

export default function Upload({ setImage, text }) {
    return (
        <div>
            {/* <div>
        <input type="file" onChange={(e) => setImage(e.target.files[0])}></input>
      </div> */}

            <Button
                variant="contained"
                component="label"
            >
                {text}
                <input
                    type="file"
                    hidden
                    onChange={(e) => setImage(e.target.files[0])}
                />
            </Button>

        </div>
    )
}