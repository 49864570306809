import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import { backendUrl } from '../../../index'
import { useContext } from '../../../context/general/context'
// ----------------------------------------------------------------------

export default function AddUserForm() {
    const { get, post, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();
    const { state } = useContext()
    const RegisterSchema = Yup.object().shape({
        email: Yup.string().email('Introduceti un email valid!').required('Email-ul este obligatoriu!'),
        firstName: Yup.string().min(1, 'Prenumele este prea scurt!').max(50, 'Prenumele este prea lung!').required('Prenumele este obligatoriu!'),
        lastName: Yup.string().min(1, 'Numele este prea scurt!').max(50, 'Numele este prea lung!').required('Numele este obligatoriu!'),
        password: Yup.string().required('Parola este obligatorie!')//validare minim 6 caractere
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            await post(`restaurants/${state.currentRestaurantId}/add-staff`, { ...values })
            navigate('/dashboard/user', { replace: true });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Prenume"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                        />

                        <TextField
                            fullWidth
                            label="Nume"
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            autoComplete="off"
                            value=""
                            type="email"
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <TextField
                            fullWidth
                            autoComplete="off"
                            value=""
                            type={showPassword ? 'text' : 'password'}
                            label="Parola"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                    </Stack>

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Adauga
          </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
