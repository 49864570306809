import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import CurrentRestaurrantPopover from './CurrentRestaurrantPopover';
import NotificationsPopover from './NotificationsPopover';
import AddRestaurantButton from './AddRestaurantButton'

import {useContext} from '../../context/general/context'
import { backendUrl } from '../../index'
import { useRef, useState, useEffect } from 'react';
import useFetch from 'use-http'

import CircularProgress from '@mui/material/CircularProgress';
import { useFeature, MODULE_ORDER, COLOR, STYLED_BOTTOM_BUTTON, INFO , LANGUAGE,AVATAR_ROUND} from '../../context/feature/context'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};


export default function DashboardNavbar({ onOpenSidebar }) {

  const { state, dispatch } = useContext();
  const { state: featureState, dispatch: featureDispatch } = useFeature()
  const [restaurantSimple, setRestaurantSimple] = useState()

  const restaurantFetch = useFetch(backendUrl + '/restaurants')

  async function loadRestaurantSimple() {
    // if (state.currentRestaurantId) {
      const response = await restaurantFetch.get(state.currentRestaurantId + "?simple=true")
      if (restaurantFetch.response.ok) {
          setRestaurantSimple(response)
      }
    // }
  }

  useEffect(() => {
    loadRestaurantSimple()
  }, [state.language, state.currentRestaurantId])

  useEffect(() => {
    if (restaurantSimple && restaurantSimple.features) {
      console.log("restaurantSimple")
      console.log(restaurantSimple)
        featureDispatch({ type: "INIT", payload: restaurantSimple.features })
    }
  }, [restaurantSimple])



  if (!restaurantSimple) return <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  minHeight="100vh"
                                  
                                  >
                                      <CircularProgress />
                                </Box>

  // if (!featureState.features) return <Box
  //                                     display="flex"
  //                                     justifyContent="center"
  //                                     alignItems="center"
  //                                     minHeight="100vh"
  //                                   >
  //                                     <CircularProgress />
  //                                   </Box>
  const languageFeature = featureState?.features?.hasOwnProperty(LANGUAGE) ? featureState.features[LANGUAGE] : null
  console.log("LANGUAGE")
  console.log(featureState?.features)

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        
          <AddRestaurantButton />
          {languageFeature && <LanguagePopover languages={languageFeature.split(',')} />}
          <CurrentRestaurrantPopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
