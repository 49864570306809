import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack,Avatar, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import uploadTaskPromise, { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'
import {changeCurrentRestaurant} from '../../../context/general/actions'
// ----------------------------------------------------------------------
import Upload from '../products/uploader/Upload'

export default function AddRestaurantForm() {
    const {get, post, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();
    
    const [imgUrlProfile, setImgUrlProfile] = useState();
    const [imgUrlCover, setImgUrlCover] = useState();

    const [imageProfile, setImageProfile] = useState();
    const [imageCover, setImageCover] = useState();

    const RegisterSchema = Yup.object().shape({
        staffMember: Yup.string().required('StaffMember este obligatoriu!'),
        name: Yup.string().min(1, 'Numele restaurantului este prea scurt!').max(50, 'Numele restaurantului este prea lung!').required('Numele restaurantului este obligatoriu!'),
        profileUrlImg: Yup.string(),
        coverUrlImg: Yup.string(),
        description: Yup.string().min(1, 'Descrierea este prea scurta!').max(255, 'Descrierea este prea lunga!').required('Descrierea este obligatorie!')
    });

    async function uploadImage(image, setImgUrl) {
        // console.log("uploadImage")
        // const data = new FormData()
        // data.append("file", image)
        // data.append("upload_preset", "hps3oejj")
        // data.append("cloud_name", "daqirrhrb")

        // const result = await fetch("https://api.cloudinary.com/v1_1/daqirrhrb/image/upload", {
        //     method: "post",
        //     //   headers: {},
        //     body: data
        // })

        // const json = await result.json()

        const imageUrl = await uploadTaskPromise(image, state.currentRestaurantId)

        setImgUrl(imageUrl)

        return imageUrl
    }


    const {state, dispatch} = useContext()
    // console.log(state.userDetails.sub)

    const formik = useFormik({
        initialValues: {
            staffMember: state.userDetails.sub,
            name: '',
            profileUrlImg: '',
            coverUrlImg: '',
            description: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            const urlProfile = await uploadImage(imageProfile, setImgUrlProfile)
            const urlCover = await uploadImage(imageCover, setImgUrlCover)

            await post(`restaurants`, {...values, profileUrlImg: urlProfile, coverUrlImg: urlCover})
            changeCurrentRestaurant(dispatch, Date.now())
            navigate('/dashboard/tables', { replace: true });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    
                    <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    
                    <TextField
                        fullWidth
                        label="description"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                    />
                    
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        {/* <TextField
                            fullWidth
                            label="profileUrlImg"
                            {...getFieldProps('profileUrlImg')}
                            error={Boolean(touched.profileUrlImg && errors.profileUrlImg)}
                            helperText={touched.profileUrlImg && errors.profileUrlImg}
                        /> */}

                        {/* <TextField
                            fullWidth
                            label="coverUrlImg"
                            {...getFieldProps('coverUrlImg')}
                            error={Boolean(touched.coverUrlImg && errors.coverUrlImg)}
                            helperText={touched.coverUrlImg && errors.coverUrlImg}
                        /> */}
                          <Upload setImage={setImageProfile} text="Adauga poza de profil" />
                    {imageProfile &&
                        <Avatar
                            src={URL.createObjectURL(imageProfile)}
                            variant="square"
                            sx={{ width: "10%", height: "100%" }}
                        >
                        </Avatar>
                    }
                      <Upload setImage={setImageCover} text="Adauga poza pentru cover" />
                    {imageCover &&
                        <Avatar
                            src={URL.createObjectURL(imageCover)}
                            variant="square"
                            sx={{ width: "10%", height: "100%" }}
                        >
                        </Avatar>
                    }
                    </Stack>
               
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Adauga
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
