import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistance } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, CircularProgress,  TablePagination, } from '@mui/material';
// utils
import { mockImgCover } from '../../../utils/mockImages';
//
import Scrollbar from '../../Scrollbar';
import { useContext } from '../../../context/general/context'

import { backendUrl } from '../../../index'
import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

// const NEWS = [...Array(5)].map((_, index) => {
//   const setIndex = index + 1;
//   return {
//     title: faker.name.title(),
//     description: faker.lorem.paragraphs(),
//     image: mockImgCover(setIndex),
//     postedAt: faker.date.soon()
//   };
// });

// ----------------------------------------------------------------------

OnlineOrderItem.propTypes = {
  order: PropTypes.object.isRequired
};

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

function OnlineOrderItem({ order }) {
  const { id, image, title, status, timestamp, type } = order;
  const name = (new Date(parseInt(title))).toUTCString()
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5 }}
      />
      
      <Box sx={{ minWidth: 240 }}>
        <Link to={`/dashboard/order/${id}`} color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {status}
        </Typography>
      </Box>
      <Box>
        <Typography>
          {type === 'ONLINE_DELIVERY' && 'DELIVERY'}
          {type === 'ONLINE_PICKUP' && 'PICKUP'}
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {formatDistance(new Date(parseInt(timestamp)).addHours(2), new Date())} ago
      </Typography>
      
    </Stack>
  );
}

export default function CurrentOrders() {
      
  const [orders, setOrders] = useState()
  const [notFound, setNotFound] = useState(false)
  const { state } = useContext()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalOnlineOrders, setTotalOnlineOrders] = useState(0);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function loadOrders() {
    if (state.currentRestaurantId) {
      const response = await fetch(backendUrl + `/orders/restaurant/${state.currentRestaurantId}/online?pageNumber=${page}&pageSize=${rowsPerPage}`)
      const json = await response.json()
      if (response.ok) {
        setOrders(json.content)
        setTotalOnlineOrders(json.totalElements)
      }
        else setNotFound(true)
    } else {
      setOrders([])
  }
  }

  useEffect(() => {
      loadOrders()    
  }, [page, rowsPerPage, totalOnlineOrders, state.currentRestaurantId])

  if (notFound) return "Not Found"
  if (!orders) return (
  <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      >
      <CircularProgress size={70} />
  </Box>)

  const newOrders = orders.map((order, index) => {
    const setIndex = index + 1;
    return {
      id: order.id,
      title: order.timestamp,
      status: order.status,
      image: mockImgCover(setIndex),
      timestamp: order.timestamp,
      type: order.type
    };
  });
  
  return (
    <Card>
      <CardHeader title="Online" />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {newOrders.map((order) => (
            <OnlineOrderItem key={order.title} order={order} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />


      <TablePagination
            labelRowsPerPage="Randuri per pagina"
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={totalOnlineOrders}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Card>
  );
}
