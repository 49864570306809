import { useState } from 'react';
import {
    Avatar,
    Button,
    Box
  } from '@mui/material';
import {useContext} from '../../../../context/general/context'
import {backendUrl} from '../../../../index'
import { Icon } from '@iconify/react';
  
export default function QRButton({tableId}) {
    const [qrImage, setqrImage] = useState();
    const{ state } = useContext()

    async function handleGenerateQR() {
        
        const response = await fetch(backendUrl + '/qr/' + (tableId ? `${state.currentRestaurantId}/tables/${tableId}` : `key/${state.currentRestaurantId}`))
        const file = await response.blob()
        if (response.ok) {
            setqrImage(URL.createObjectURL(file))
        }
      
    }

    return <>
        <Box display='flex' flexDirection='column'>
            <Box>
            {!qrImage &&
                <Button startIcon={<Icon icon="mdi:qrcode-scan" />} variant="outlined" onClick={() => handleGenerateQR()}>Genereaza</Button>
            }
            </Box>
            <Box>
                {qrImage ? <img src={qrImage}></img> : null}
                {/* {qrImage ? <Avatar variant="square" sx={{width:'100%', height: '100%'}} src={qrImage}></Avatar> : null} */}
            </Box>
        </Box>
    </>
}