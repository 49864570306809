// material
import {useState, useEffect} from 'react'
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {useContext} from '../context/general/context'
import {backendUrl} from '../index'
import TableSqare from '../components/_dashboard/orders/TableSquare'
// ----------------------------------------------------------------------

export default function TableOrders() {

    const [tables, setTables] = useState()
    const {state} = useContext()
    useEffect(async () => {
      if (state.currentRestaurantId !== "") {
        const response = await fetch(backendUrl + `/restaurants/${state.currentRestaurantId}/tables`)
        const json = await response.json()
        if (response.ok) {
          setTables(json)
        }
      } else {
        setTables([])
      }
        
    }, [state.currentRestaurantId ])
    if (!tables) return 'Loading'
  return (
    <Page title="Dashboard | Reach">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          {tables.map(table => (<Grid item xs={12} sm={6} md={3}>
            <TableSqare name={table.name} id={table.id} />
          </Grid>))}

        </Grid>
      </Container>
    </Page>
  );
}
