import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'
// ----------------------------------------------------------------------

export default function AddTableForm() {
    const {state} = useContext()
  const {get, post, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().min(1, 'Numarul mesei este prea scurt!').max(3, 'Numarul mesei este prea lung!').required('Numarul mesei este obligatoriu!'),
        restaurantId: Yup.string().required('restaurantId este obligatoriu!'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            restaurantId: state.currentRestaurantId,
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            await post(`tables`, {...values})
            navigate('/dashboard/tables', { replace: true });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Numar masa"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                   
                    </Stack>
                    
               
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Adauga
          </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
