import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
// material
import {
  useNavigate
} from "react-router-dom";
import { Container,
  Stack,
  Typography,
  CircularProgress,
  Box,
  Link
} from '@mui/material';
import EditRestaurantForm  from '../components/_dashboard/restaurants/EditRestaurantForm';

// components
import Page from '../components/Page';
import QRButton from '../components/_dashboard/tables/qr/QRButton';

import {useContext} from '../context/general/context'
import { backendUrl } from '../index'
// ----------------------------------------------------------------------

export default function EditRestaurant() {
  const [openFilter, setOpenFilter] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const {state, dispatch} = useContext();
  const [loading, setLoading] = useState(false);
  const language = state.language

  useEffect(async () => {
    if (state.currentRestaurantId) {
      setLoading(true)
      const response = await fetch(backendUrl + '/restaurants/' + state.currentRestaurantId + "?simple=true" + (language === 'ro' ? '' : `&lang=${language}`))
      const json = await response.json()
      if (response.ok) {
          setRestaurant(json)
          setLoading(false)
      }

      window.scrollTo(0, 0)

    }
    
  }, [state.currentRestaurantId, state.globalUpdate, state.language])

  if (!state.currentRestaurantId) return <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minHeight="100vh"
                                          >
                                              <Typography sx={{fontSize: 36}} >
                                                Adaugati un restaurant!
                                              </Typography>
                                        </Box>

  if (!restaurant) return <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            >
                                <CircularProgress size={70} />
                          </Box>

  if (loading) return <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            >
                                <CircularProgress size={70} />
                          </Box>

  console.log("Restaurant parinte")
  console.log(restaurant)
  return (
    <Page title="Dashboard: Products | Reach">
      <Container>
       
       <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column">
            <Box mr={2}>
              <a
                href={`https://www.reach-menu.com/${restaurant.urlKey}`}
                target="_blank"
                rel="noopener noreferrer">
                <Typography>
                  https://www.reach-menu.com/{restaurant.urlKey}
                </Typography>
              </a>
            </Box>
            
          
          
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Modifica restaurantul
              </Typography>
            </Stack>
          </Box>

          <Box>
            <QRButton/>
          </Box>
        </Box>

        <EditRestaurantForm restaurant={restaurant} />
      </Container>
    </Page>
  );
}
