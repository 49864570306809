import * as React from 'react'
import {initialState, reducer} from './reducer'
import {Navigate} from 'react-router-dom'
const Context = React.createContext()

function ContextProvider({children}) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = {state, dispatch}
  return <Context.Provider value={value}>{children}</Context.Provider>
}
function useContext() {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useContext must be used within a Provider')
  }
  return context
}
export {ContextProvider, useContext}