import PropTypes from 'prop-types';
// material
import { Grid, CircularProgress, List, ListItem } from '@mui/material';
import ShopProductCard from './ProductCard';
import { backendUrl } from '../../../index'
import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useNavigate
} from "react-router-dom";
import { Stack, Button } from '@mui/material';

// ----------------------------------------------------------------------
import { useContext } from '../../../context/general/context'
import { Box, Typography } from '@mui/material';


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid ,
  margin: `0 ${grid}px 0 0`,
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});
export default function ProductList({ ...other }) {
  const [restaurant, setRestaurant] = useState()
  const [products, setProducts] = useState()
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const { state } = useContext()
  const language = state.language
  async function loadProducts() {
    if (state.currentRestaurantId !== "") {
      setLoading(true)
      const response = await fetch(backendUrl + `/restaurants/${state.currentRestaurantId}?showAll=true` + (language === 'ro' ? '' : `&lang=${language}`))
      const json = await response.json()
      if (response.ok) setRestaurant(json)
    } else {
      setRestaurant({ categories: [] })
    }
    setLoading(false)
    window.scrollTo(0, state.position)
    console.log("***********************" + state.position)

  }

  useEffect(() => {
    loadProducts()
  }, [state.currentRestaurantId, updated, state.globalUpdate, state.language])


  if (!restaurant?.categories) return <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        minHeight="100vh"
                                        >
                                            <CircularProgress size={70} />
                                      </Box>

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDragEnd(result, products) {
    if (!result.destination) {
      return;
    }

    // if (result.destination.index === result.source.index) {
    //   return;
    // }

    const items = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    console.log("items")
    console.log(items)

    const body = items.map((elem, index) => {
      return {
        id: elem.id,
        priority: index
      }
    })
    console.log(body)
    setLoading(true)
    await fetch(backendUrl + `/products`, {
      method: 'PUT', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
    setUpdated(Date.now())
  }

  if (loading)
    return <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
    </Box>


  return (
    <>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
      Produse
      </Typography>
      <Button
        disabled={state.currentRestaurantId === "" || restaurant.categories.length === 0}
        variant="contained"
        component={RouterLink}
        to="/dashboard/add-product"
        startIcon={<Icon icon={plusFill} />}
      >
        Adauga produs
      </Button>
    </Stack>
    {!state.currentRestaurantId && <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            >
                                <Typography sx={{fontSize: 36}}>
                                  Adaugati un restaurant!
                                </Typography>
                          </Box>
    }
    <Grid container spacing={3} {...other}>

      {restaurant.categories.map((category) => (
        <Grid key={category.id} item xs={12} sm={12} md={12}>

          <Box mb={4}> <Typography fontSize={28}>
            {category.name}
          </Typography></Box>


          <DragDropContext onDragEnd={(result) => onDragEnd(result, category.products)}>
            <Droppable direction="horizontal" droppableId={category.id}>
              {(provided, snapshot) => (
                 <div
                 ref={provided.innerRef}
                 style={getListStyle(snapshot.isDraggingOver)}
                 {...provided.droppableProps}
               >
                    {category.products.map((product, index) => (
                      <Draggable key={product.id} draggableId={product.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                            <ShopProductCard product={product} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

          </DragDropContext>



          {/* </Box> */}

        </Grid>
      ))}


      {/* {restaurant.categories[3].products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} />
        </Grid>
      ))} */}


    </Grid>
    </>
  );
  
}
