import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material

import { Stack, Avatar, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import uploadTaskPromise, { backendUrl } from '../../../index'
import { useContext } from '../../../context/general/context'
import { changeCurrentRestaurant } from '../../../context/general/actions'
// ----------------------------------------------------------------------
import Upload from '../products/uploader/Upload'
import { ref, uploadBytes } from "firebase/storage";

export default function EditRestaurantForm({ restaurant }) {
    const { get, post, put, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();
    const { state, dispatch } = useContext()

    const [imgUrlProfile, setImgUrlProfile] = useState();
    const [imgUrlCover, setImgUrlCover] = useState();

    const [imageProfile, setImageProfile] = useState();
    const [imageCover, setImageCover] = useState();


    const RegisterSchema = Yup.object().shape({
        urlKey: Yup.string(),
        name: Yup.string().min(1, 'Numele restaurantului este prea scurt!').max(50, 'Numele restaurantului este prea lung!').required('Numele restaurantului este obligatoriu!'),
        profileUrlImg: Yup.string(),
        coverUrlImg: Yup.string(),
        description: Yup.string().min(1, 'Descrierea este prea scurta!').max(255, 'Descrierea este prea lunga!').required('Descrierea este obligatorie!'),
        ssid: Yup.string().max(50, 'Numele retelei wifi este prea lunga!').nullable(),
        wifiPassword: Yup.string().max(50, 'Parola retelei wifi este prea lunga!').nullable(),
        address: Yup.string().max(255, 'Adresa este prea lunga!').nullable(),
        latitude: Yup.string().max(255, 'Latitudinea este prea lunga!').nullable(),
        longitude: Yup.string().max(255, 'Longitudinea este prea lunga!').nullable(),
        program: Yup.string().max(255, 'Programul este prea lung!').nullable(),
        email: Yup.string().max(50, 'Emailul este prea lung!').nullable(),
        phone: Yup.string().max(20, 'Numarul de telefon este prea lung!').nullable()
    });


   

    async function uploadImage(image, setImgUrl) {

        const imageUrl = await uploadTaskPromise( image, state.currentRestaurantId)
        console.log("uploadImage")
        const data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "hps3oejj")
        data.append("cloud_name", "daqirrhrb")
        setImgUrl(imageUrl)
        return imageUrl
    }


    const language = state.language

    const { id, urlKey, name, profileUrlImg, coverUrlImg, description, ssid, wifiPassword, address, latitude, longitude, program, email, phone } = restaurant;
    console.log('NAME')
    console.log(name)

    const formik = useFormik({
        initialValues: {
            name: name,
            urlKey: urlKey,
            profileUrlImg: profileUrlImg,
            coverUrlImg: coverUrlImg,
            description: description,
            ssid: ssid,
            wifiPassword: wifiPassword,
            address: address,
            latitude: latitude,
            longitude: longitude,
            program: program,
            email: email,
            phone: phone
        },
        enableReinitialize: true,
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {

            const newUrlProfile = imageProfile ? await uploadImage(imageProfile, setImgUrlProfile) : profileUrlImg
            const newUrlCover = imageCover ? await uploadImage(imageCover, setImgUrlCover) : coverUrlImg

            await fetch(backendUrl + `/restaurants/${id}` + (language === 'ro' ? '' : `?lang=${language}`), {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({ ...values, profileUrlImg: newUrlProfile, coverUrlImg: newUrlCover })
            })

            dispatch({ type: "GLOBAL_UPDATE" })
            navigate('/dashboard/edit-restaurant', { replace: true });
        }
    });


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nume"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                        <TextField
                            fullWidth
                            label="Nume URL"
                            {...getFieldProps('urlKey')}
                            error={Boolean(touched.urlKey && errors.urlKey)}
                            helperText={touched.urlKey && errors.urlKey}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        label="Descriere"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                    />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        {/* <TextField
                            fullWidth
                            label="profileUrlImg"
                            {...getFieldProps('profileUrlImg')}
                            error={Boolean(touched.profileUrlImg && errors.profileUrlImg)}
                            helperText={touched.profileUrlImg && errors.profileUrlImg}
                        /> */}

                        {/* <TextField
                            fullWidth
                            label="coverUrlImg"
                            {...getFieldProps('coverUrlImg')}
                            error={Boolean(touched.coverUrlImg && errors.coverUrlImg)}
                            helperText={touched.coverUrlImg && errors.coverUrlImg}
                        /> */}
                        <Upload setImage={setImageProfile} text="Schimba poza de profil" />
                        {imageProfile ?
                            <Avatar
                                src={URL.createObjectURL(imageProfile)}
                                variant="square"
                                sx={{ width: "10%", height: "100%" }}
                            >
                            </Avatar>
                            :
                            <Avatar
                                src={restaurant.profileUrlImg}
                                variant="square"
                                sx={{ width: "10%", height: "100%" }}
                            >
                            </Avatar>
                        }

                        <Upload setImage={setImageCover} text="Schimba poza de coperta" />
                        {imageCover ?
                            <Avatar
                                src={URL.createObjectURL(imageCover)}
                                variant="square"
                                sx={{ width: "10%", height: "100%" }}
                            >
                            </Avatar>
                            :
                            <Avatar
                                src={restaurant.coverUrlImg}
                                variant="square"
                                sx={{ width: "10%", height: "100%" }}
                            >
                            </Avatar>
                        }

                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nume Retea Wifi"
                            {...getFieldProps('ssid')}
                            error={Boolean(touched.ssid && errors.ssid)}
                            helperText={touched.ssid && errors.ssid}
                        />

                        <TextField
                            fullWidth
                            label="Parola Wifi"
                            {...getFieldProps('wifiPassword')}
                            error={Boolean(touched.wifiPassword && errors.wifiPassword)}
                            helperText={touched.wifiPassword && errors.wifiPassword}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        label="Adresa"
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                    />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Latitudine"
                            {...getFieldProps('latitude')}
                            error={Boolean(touched.latitude && errors.latitude)}
                            helperText={touched.latitude && errors.latitude}
                        />

                        <TextField
                            fullWidth
                            label="Longitudine"
                            {...getFieldProps('longitude')}
                            error={Boolean(touched.longitude && errors.longitude)}
                            helperText={touched.longitude && errors.longitude}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        multiline
                        label="Program"
                        {...getFieldProps('program')}
                        error={Boolean(touched.program && errors.program)}
                        helperText={touched.program && errors.program}
                    />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <TextField
                            fullWidth
                            label="Numar de telefon"
                            {...getFieldProps('phone')}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                        />
                    </Stack>

                    <LoadingButton
                        disabled={!state.currentRestaurantId}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Salveaza
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
