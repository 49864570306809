import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BlockIcon from '@mui/icons-material/Block';

import DeleteDialog from './DeleteDialog';
import { useState } from 'react'

import { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

  const [open, setOpen] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [loadingAvailable, setLoadingAvailable] = useState(false);

  const {state, dispatch} = useContext()

  function handleOnClickDelete() {
    setOpen(true)
  }
  function handleOnClick() {
    dispatch({ type: 'setPosition', payload: window.pageYOffset });
    navigation("/dashboard/edit-product", {state: product})
  }

  const { id, name, imgUrl, price, colors, status, visible, available } = product;
  const [currentVisible, setCurrentVisible] = useState(visible || visible == undefined);
  const [currentAvailable, setCurrentAvailable] = useState(available || available == undefined);

  async function handleOnClickVisible() {
    setLoadingVisible(true)
    await fetch(backendUrl + `/products/${id}/state`, {method: 'PUT', headers: {
      'Content-Type': 'application/json'
      }, body: JSON.stringify({visible : !visible})
    })
    setLoadingVisible(false)
    setCurrentVisible(!visible)
    dispatch({ type: "GLOBAL_UPDATE"})
  }

  async function handleOnClickAvailable() {
    setLoadingAvailable(true)
    await fetch(backendUrl + `/products/${id}/state`, {method: 'PUT', headers: {
      'Content-Type': 'application/json'
      }, body: JSON.stringify({available : !available})
    })
    setLoadingAvailable(false)
    setCurrentAvailable(!available)
    dispatch({ type: "GLOBAL_UPDATE"})
  }

  const navigation = useNavigate()

  let width = "15vw"

  width = matchesXl ? "20vw" : width 

  width = matchesLg ? "25vw" : width 

  width = matchesMd ? "35vw" : width 

  width = matchesSm ? "70vw" : width 

  return (
    <>
    <DeleteDialog open={open} setOpen={setOpen} id={id} name={name} />
    <Card sx={{minWidth:width}} 
    // onClick={() => navigation("/dashboard/edit-product", {state: product})}  
    >
       <Box sx={{ pt: '100%', position: 'relative' , }}>
          {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase'
              }}
            >
              {status}
            </Label>
          )}
          <ProductImgStyle alt={name} src={imgUrl + "?tr=h-300"} onClick={handleOnClick}/>
          <Tooltip title="Seteaza disponibilitatea produsului" placement="top">
            <Fab
              onClick={handleOnClickAvailable}
              size="small" 
              sx={{ '&:hover': {backgroundColor: '#fff' },
                  backgroundColor: '#fff',
                  color:"#000",
                  zIndex: 10,
                  position: "absolute",
                  left: "44%",
                  top: '5%',
                }}
                color="secondary"
                aria-label="add">
                {loadingAvailable ? 
                  <CircularProgress /> : 
                  currentAvailable ? 
                    <CheckCircleIcon color="primary" /> :
                    <BlockIcon color="default" />
                }
                
            </Fab>
          </Tooltip >
          <Tooltip title="Seteaza vizibilitatea produsului in meniu" placement="top">
            <Fab
              onClick={handleOnClickVisible}
              size="small" 
              sx={{ '&:hover': { backgroundColor: '#fff' },
                  backgroundColor: '#fff',
                  color:"#000",
                  zIndex: 10,
                  position: "absolute",
                  left: "62%",
                  top: '5%',
                }}
                color= "secondary"
                aria-label="add">
                {loadingVisible ? 
                  <CircularProgress /> : 
                  currentVisible ? 
                    <VisibilityIcon color="primary" /> :
                    <VisibilityOffIcon color="default" />
                }
            </Fab>
          </Tooltip>

          <Tooltip title="Sterge produsul" placement="top">
            <Fab
              onClick={handleOnClickDelete}
              size="small" 
              sx={{ '&:hover': { backgroundColor: '#fff' },
                  backgroundColor: '#fff',
                  color:"#000",
                  zIndex: 10,
                  position: "absolute",
                  left: "80%",
                  top: '5%',
                }}
                color="secondary"
                aria-label="add">
                <DeleteIcon color="error" />
            </Fab>
          </Tooltip>
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">
              {price} RON
              {/* {fCurrency(price)} */}
            </Typography>
          </Stack>
        </Stack>
    </Card>
    </>
  );
}
