'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { useState, useEffect } from 'react';
import { backendUrl } from '../index'
import { useContext } from '../context/general/context'

// material
import {
    IconButton,
    CircularProgress,
    Box
  } from '@mui/material';

  import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import zIndex from '@mui/material/styles/zIndex';

function rgbToHex(r, g, b) {     
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function hexToRgb(hex) {
    var bigint = parseInt(hex.substring(1), 16);
    var red = (bigint >> 16) & 255;
    var green = (bigint >> 8) & 255;
    var blue = bigint & 255;
    const color = {
        r: red,
        g: green,
        b: blue,
        a: '1',
    }
    return color
}

function ColorPicker({ setTrigger, id, existingColor }) {

  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(hexToRgb(existingColor))
  const [isEditMode, setIsEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext()

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
    setTrigger(true)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
    setIsEditMode(true)
    setTrigger(false)
  };

  const handleChange = (color) => {
      setColor(color.rgb)
  };

  async function onSave() {
    setLoading(true)

    await fetch(backendUrl + '/restaurants-features/' + id, {
      method: 'PUT', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(
        { payload: rgbToHex(color.r, color.g, color.b) }
      )
    })

    setLoading(false)
    setIsEditMode(false)

    dispatch({ type: "GLOBAL_UPDATE" })
  }

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      },
    },
  });
   
    return (
        <Box zIndex={1000} display="flex" justifyContent="flex-start" alignItems="center">
        <Box mr={1} alignItems="center" justifyContent="center">
            <div style={ styles.swatch } onClick={ handleClick }>
            <div style={ styles.color } />
            </div>
    
            { displayColorPicker ? 
                <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ handleClose }/>

                <SketchPicker sx={{ zIndex:200}} color={ color } onChange={ handleChange } />
                </div> 
            : 
            null 
            }
        </Box>
        
        <Box mb={1}>
            {isEditMode &&
                    <IconButton
                    sx={{ color: "#00c853" }}
                    aria-label="done"
                    onClick={onSave}
                    disabled={loading}
                    >
                    {loading ? <CircularProgress /> : <DoneOutlineIcon />}
                    </IconButton>
                }
        </Box>
    </Box>
    
    )
  }

export default ColorPicker