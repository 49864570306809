import { useState, useEffect } from 'react';

// components
import { useLocation } from 'react-router-dom'

import Page from '../components/Page';
import AdminFeatureUnavailable from '../components/_dashboard/features/AdminFeatureUnavailable'
import AdminFeatureAvailable from '../components/_dashboard/features/AdminFeatureAvailable'
import {
   
Box  } from '@mui/material';
import { backendUrl } from '../index'
import { useContext } from '../context/general/context'


export default function AdminFeature() {
    const { state: feature } = useLocation()

    const [restaurants, setRestaurants] = useState()
    const [restaurantsAvailable, setRestaurantsAvailable] = useState()

    const { state } = useContext()

    useEffect(async () => {
        if (state.currentRestaurantId) {
            const response = await fetch(backendUrl + '/restaurants')
            const json = await response.json()
            if (response.ok) {
                setRestaurants(json)
            }
        } else {
            setRestaurants([])
        }

    }, [state.currentRestaurantId, state.globalUpdate])

    useEffect(async () => {
        if (state.currentRestaurantId) {
            const response = await fetch(backendUrl + '/restaurants-features/' + feature.id + '/restaurants')
            const json = await response.json()
            if (response.ok) {
                const map = new Map()
                json.forEach(restaurantFeature => map.set(restaurantFeature.restaurantId, restaurantFeature.id))
                setRestaurantsAvailable(map)
            }
        } else {
            setRestaurantsAvailable(new Map())
        }

    }, [state.currentRestaurantId, state.globalUpdate])



    if (!restaurants) return 'Loading...'
    if (!restaurantsAvailable) return 'Loading...'

    const restaurantsWithFeature = restaurants
            .filter(restaurant => restaurantsAvailable.has(restaurant.id))
            .map(restaurant => ({...restaurant, restaurantFeatureId: restaurantsAvailable.get(restaurant.id)}))

    const restaurantsWithoutFeature = restaurants.filter(restaurant => !restaurantsAvailable.has(restaurant.id))



    return (
        <Page title="User | Reach">
            <AdminFeatureUnavailable USERLIST={restaurantsWithoutFeature} feature={feature} />
            <Box mb={10}/>
            
            <AdminFeatureAvailable USERLIST={restaurantsWithFeature} feature={feature} />

        </Page>
    );
}
