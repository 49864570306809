import {
    Box,
    TableCell,
    Input,
    OutlinedInput,
    IconButton,
    CircularProgress
} from '@mui/material';
import { useState, useEffect } from 'react';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'

export default function EditableCell({ endpoint, row, column, method = "PUT" }) {

    const [value, setValue] = useState(row[column])
    const [isEditMode, setIsEditMode] = useState(false)

    const [loading, setLoading] = useState(false)

    const {state, dispatch} = useContext()

    const onChange = (event) => {
        setIsEditMode(true)
        setValue(event.target.value)
    }

    async function onSave() {
        setLoading(true)

        row[column] = value

        await fetch(backendUrl + endpoint, {method, headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify(
            row
        )})

        setLoading(false)
        dispatch({ type: "GLOBAL_UPDATE"})
        setIsEditMode(false)

    }

    return (
        <TableCell align="left">
            <Input
                // disableUnderline
                value={value}
                name={column}
                onChange={e => onChange(e)}
            />
            {isEditMode &&
                <IconButton
                    sx={{color:"#00c853"}}
                    aria-label="done"
                    onClick={onSave}
                    disabled={loading}
                >
                    {loading ? <CircularProgress /> : <DoneOutlineIcon />}
                </IconButton>
            }
        </TableCell>
    )
}