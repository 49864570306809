import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';
// components
import Logo from '../components/Logo';
//
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <Grid container spacing={2} display="flex" justifyContent="space-between">
        <Grid item md={6} sm={6} xs={12}>
          <MHidden width="mdUp">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </MHidden>
        </Grid>

        <Grid item md={6} lg={6}>
              <MHidden width="smDown">
                <Typography
                  variant="body2"
                  sx={{
                    mt: { md: -2 }
                  }}
                >
                  {children}
                </Typography>
              </MHidden>
        </Grid>
      </Grid>
    </HeaderStyle>
  );
}
