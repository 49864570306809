// material
import { Box, Grid, Container, Typography, CircularProgress } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  UniqueTableVisitorsCard,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  CurrentOrders,
  UniqueOnlineVisitorsCard,
  GraphWebsiteVisitsByDay,
  GraphScansByDay
} from '../components/_dashboard/app';

import { backendUrl } from '../index'
import { useState, useEffect } from 'react';
import useFetch from 'use-http'
import { useContext } from '../context/general/context'

// ----------------------------------------------------------------------

export default function DashboardApp() {

  const [restaurant, setRestaurant] = useState();
  const {state, dispatch} = useContext();
  const [loading, setLoading] = useState(false);
  const language = state.language

  useEffect(async () => {
    if (state.currentRestaurantId) {
      setLoading(true)
      const response = await fetch(backendUrl + '/restaurants/' + state.currentRestaurantId + "?simple=true" + (language === 'ro' ? '' : `&lang=${language}`))
      const json = await response.json()
      if (response.ok) {
          setRestaurant(json)
          setLoading(false)
      }

      window.scrollTo(0, 0)

    }
    
  }, [state.currentRestaurantId, state.globalUpdate, state.language])

  if (!state.currentRestaurantId) return <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minHeight="100vh"
                                          >
                                              <Typography sx={{fontSize: 36}} >
                                                Adaugati un restaurant!
                                              </Typography>
                                        </Box>

  if (!restaurant) return <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            >
                                <CircularProgress size={70} />
                          </Box>

  if (loading) return <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            >
                                <CircularProgress size={70} />
                          </Box>

  return (
    <Page title="Dashboard | Reach">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Bine ai venit!</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <UniqueTableVisitorsCard restaurant={restaurant} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <UniqueOnlineVisitorsCard restaurant={restaurant}/>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}

          {/* <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits/>
          </Grid> */}

          <Grid item xs={12} md={12} lg={12}>
            <GraphScansByDay restaurant={restaurant}/>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <GraphWebsiteVisitsByDay restaurant={restaurant}/>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}> */}
            {/* <AppNewsUpdate /> */}
            {/* <CurrentOrders />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
