import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

import { backendUrl } from '../../../index'
import { useState, useEffect } from 'react';
// ----------------------------------------------------------------------



export default function GraphWebsiteVisitsByDay( {restaurant} ) {
  const [usersByDay, setUsersByDay] = useState()

  async function getUniqueUsersByDay() {
    console.log(Date.now())
    const currentDate = Date.now()
    const xDaysAgoDate = currentDate - 7 * 24 * 60 * 60 * 1000

    let response = await fetch(backendUrl + `/aaaa/uniqueUsers/online/daily?page=www.reach-menu.com/${restaurant.urlKey}&restaurantKey=${restaurant.urlKey}&startDate=${xDaysAgoDate}&endDate=${currentDate}` )
    
		let data = await response.json();
    setUsersByDay(data)
  }

  useEffect(async () => {
    getUniqueUsersByDay()
  }, [])

  if (!usersByDay) return "Loading"

  console.log(Object.keys(usersByDay.usersByDay))

  const CHART_DATA = [
    {
      name: 'Vizitatori',
      data: Object.values(usersByDay.usersByDay)
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] }, 
    plotOptions: { bar: { columnWidth: '31%', borderRadius: 4 } },
    legend: {show: true},
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: Object.keys(usersByDay.usersByDay),
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        }
      }
    }
  });


  return (
    <Card>
      <CardHeader title="Vizitatori online"  />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
