import { useFormik } from 'formik';
import { useState } from 'react';
// material
import {
  useNavigate
} from "react-router-dom";
import { Container, Stack, Typography } from '@mui/material';
import AddUserForm  from '../components/_dashboard/user/AddUserForm';

// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function AddUser() {
  const [openFilter, setOpenFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };
  const navigate = useNavigate()

  return (
    <Page title="Dashboard: Products | Reach">
      <Container>
       
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Adauga un utilizator
          </Typography>
        </Stack>
     

        <AddUserForm />
      </Container>
    </Page>
  );
}
