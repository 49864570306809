import { useFormik } from 'formik';
import { useState } from 'react';
// material
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { Container, Stack, Typography } from '@mui/material';
import EditProductForm  from '../components/_dashboard/products/EditProductForm';

// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function EditProduct() {

  const {state} = useLocation()

  const { id, name, imgUrl, price, colors, status } = state;
  console.log("state")
    console.log(state)
  return (
    <Page title="Dashboard: Products | Reach">
      <Container>
       
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Modifica produsul
          </Typography>
        </Stack>
     
  

        <EditProductForm product={state}/>
      </Container>
    </Page>
  );
}
