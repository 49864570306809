import { Icon } from '@iconify/react';
import appleFilled from '@iconify/icons-ant-design/usergroup-add-outlined';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { backendUrl } from '../../../index'
import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------


export default function UniqueOnlineVisitorsCard( { restaurant } ) {

  const [uniqueUsers, setUniqueUsers] = useState(0)

  async function getUniqueUsers() {
    let response = await fetch(backendUrl + `/aaaa/uniqueUsers/online?page=www.reach-menu.com/${restaurant.urlKey}&restaurantKey=${restaurant.urlKey}` )
    
		let data = await response.json();
    setUniqueUsers(data.numberOfUniqueUsers)
  }

  useEffect(async () => {
    getUniqueUsers()
  }, [])

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={appleFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(uniqueUsers)}</Typography>
      <Typography variant="h4" sx={{ opacity: 0.72 }}>
        Total Vizitatori Online
      </Typography>
    </RootStyle>
  );
}
