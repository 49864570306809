import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Input
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import TablesListHead from '../components/_dashboard/tables/TablesListHead';
import CatMoreMenu from '../components/_dashboard/categories/CatMoreMenu';
import { useContext } from '../context/general/context'
import { backendUrl } from '../index'
import {DraggableComponent, DroppableComponent} from '../components/_dashboard/tables/DndTable';
import EditableCell from '../components/_dashboard/categories/EditableCell'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Nume categorie', label: 'Nume categorie', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Delivery Only', label: 'Delivery Only', alignRight: false },
  { id: 'Vizualizare', label: 'Vizualizare', alignRight: false }, 
  // { id: 'Prioritate', label: 'Prioritate', alignRight: false },
  // { id: 'Numar produse', label: 'Numar produse', alignRight: false },
  { id: 'Mentiuni speciale', label: 'Mentiuni speciale', alignRight: false },
  { id: 'Mai multe', label: 'Mai multe', alignRight: true },
  //   { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Categories() {
  const [updated, setUpdated] = useState(false);

  const { state } = useContext()
  const [restaurant, setRestaurant] = useState()
  const [categories, setCategories] = useState()
  const language = state.language

  useEffect(async () => {
    if (state.currentRestaurantId !== "") {
      setLoading(true)
      const response = await fetch(backendUrl + `/restaurants/${state.currentRestaurantId}?showAll=true` + (language === 'ro' ? '' : `&lang=${language}`))
      const json = await response.json()
      if (response.ok) {
        setRestaurant(json)
        setCategories(json.categories)
      }
    } else {
      setRestaurant({categories: []})
      setCategories([])
    }
    setLoading(false)
  }, [state.currentRestaurantId, updated, state.language, state.globalUpdate])
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  async function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
        return
    }

    console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    const items = reorder(
        categories,
        result.source.index,
        result.destination.index
    )
 
    console.log("items")
    console.log(items)

    const body = items.map((elem, index) => {
      return {
        id: elem.id,
        priority: index
      }
    })
    console.log(body)
    // setCategories(items)
    setLoading(true)
    await fetch(backendUrl + `/categories`, {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify(body)})
    
    // setCategories(items)
    setUpdated(Date.now())
}

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = restaurant.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 
  if(loading) 
    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        >
          <CircularProgress size={70} />
      </Box>

  if (!categories)
    return <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      >
          <CircularProgress size={70}/>
      </Box>
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  return (
    <Page title="User | Reach">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Administreaza categorii
          </Typography>

          
          <Button
            disabled={state.currentRestaurantId === ""}
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-category"
            startIcon={<Icon icon={plusFill} />}
          >
            Adauga categorie
          </Button>
            
        </Stack>

        <Card>
          <Scrollbar>

            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TablesListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={restaurant.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody component={DroppableComponent(onDragEnd)}>
                  {categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const { id, name, priority, products, deleted, deliveryOnly, quickCategory } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          component={DraggableComponent(id, i)} key={id}
                        >

                          <TableCell align="left">{i}</TableCell>
                          <EditableCell endpoint={`/categories/${id}` + (language === 'ro' ? '' : `?lang=${language}`)} row={row} column="name"></EditableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(deleted && 'error') || 'success'}
                            >
                              {sentenceCase((deleted || false) ? 'Ascuns' : 'Vizibil')}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(deliveryOnly && 'warning') || 'info'}
                            >
                              {sentenceCase((deliveryOnly || false) ? 'Delivery Only' : 'Not Delivery Only')}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(quickCategory && 'warning') || 'info'}
                            >
                              {sentenceCase((quickCategory === true) ? 'Restransa' : 'Extinsa')}
                            </Label>
                          </TableCell>

                          {/* <TableCell align="left">
                            <IOSSwitchButton value={restaurantFeatures[id].active} id={restaurantFeatures[id].id}  />
                          </TableCell> */}
                          <EditableCell endpoint={`/categories/${id}` + (language === 'ro' ? '' : `?lang=${language}`)} row={row} column="specialWarnings"></EditableCell>
                          <TableCell align="right">
                            <CatMoreMenu categoryId={id} setUpdated={setUpdated} status={!deleted} deliveryOnly={!deliveryOnly} quickCategory={quickCategory}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage="Randuri per pagina"
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={restaurant.categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />  
        </Card>
      </Container>
    </Page>
  );
}
