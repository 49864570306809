export default function featuresReducer(state, action) {
    switch (action.type) {
        case 'INIT':
            return {
                ...state, features: action.payload.reduce(function (map, obj) {
                    map[obj.key] = obj.payload;
                    return map;
                }, {})
            };
        
        default:
            return state;
    }
}