import {backendUrl} from '../../index'

export async function loginUser(dispatch, loginPayload) {
    console.log('login')
    console.log(loginPayload)
    const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch(`${backendUrl}/authenticate`, requestOptions);
		let data = await response.json();

		if (data.token) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: data });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return data;
		}

		dispatch({ type: 'LOGIN_ERROR', error: data.error });
		console.log(data.error);
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}

export async function logout(dispatch) {
	localStorage.removeItem('currentUser');
	dispatch({ type: 'LOGOUT' });
	// localStorage.removeItem('token');
}

export async function changeCurrentRestaurant(dispatch, restaurantId) {
	dispatch({ type: 'CHANGE_CURRENT_RESTAURANT', payload: {restaurantId} });
}
