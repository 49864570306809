import { useRef, useState, useEffect } from 'react';
// material
import { Button } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton, Avatar } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';

import {useContext} from '../../context/general/context'

// fetch from backend
import { backendUrl } from '../../index'

import {changeCurrentRestaurant} from '../../context/general/actions'


// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'de',
    label: 'German',
    icon: '/static/icons/ic_flag_de.svg'
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg'
  }
];

// ----------------------------------------------------------------------

export default function CurrentRestaurantPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    
    const { state, dispatch } = useContext();
    // console.log(state.userDetails)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [restaurants, setRestaurants] = useState()

    async function loadRestaurants() {
        if (state.userDetails !== "") {
          const response = await fetch(backendUrl + '/restaurants?staffUser=' + state.userDetails.sub)
          const json = await response.json()
          if (response.ok) { 
            setRestaurants(json) 
            if (json.current) {
              changeCurrentRestaurant(dispatch, json.current.id); 
            }
          }
        }
    }

    async function changeActiveRestaurant(restaurantId) {
        console.log("change active restaurant" + restaurantId)
        await fetch(backendUrl + `/restaurants/${restaurantId}/set-current`, {method: 'POST', headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify({staffMember: state.userDetails.sub, restaurantId})})
        
        changeCurrentRestaurant(dispatch, restaurantId); 
        handleClose();
    }

    useEffect(() => {
        loadRestaurants()
    }, [state.currentRestaurantId, state.globalUpdate])

    if (!restaurants) return null

    console.log("State")
    console.log(state)
  return (
    <>
    {restaurants?.current?.profileUrlImg ? 
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        
            <Avatar alt={restaurants?.current?.name} src={restaurants?.current?.profileUrlImg} /> 
      </IconButton> 

    :
    null
}
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {restaurants.list.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === state.currentRestaurantId}
              onClick={() => changeActiveRestaurant(option.id)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                {/* <Box component="img" sx={{ maxHeight: 28 }} alt={option.name} src={option.profileUrlImg} /> */}
                <Avatar alt={option.name} src={option.profileUrlImg} /> 

              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.name}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
