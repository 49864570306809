import React from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Box from '@mui/material/Box';

export default function MultipleSelect({ formState, setFormState, items, label }) {

    const handleFieldChange = event => {
        console.log(event);
        // event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      };

    return (
        <TextField
            select
            fullWidth
            name="items"
            id="items"
            variant="outlined"
            label={label}
            SelectProps={{
                multiple: true,
                value: formState.items,
                onChange: handleFieldChange,
                MenuProps
            }}
        >
           
           {items.map(
                item =>
                    <MenuItem value={item.id}>{item.value}</MenuItem>
            )}
           
        </TextField>
    );
}
