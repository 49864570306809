import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill'
import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextOutline from '@iconify/icons-eva/file-text-outline';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import bulbOutline from '@iconify/icons-eva/bulb-outline';
import colorPaletteOutline from '@iconify/icons-eva/color-palette-outline';
import options2Outline from '@iconify/icons-eva/options-2-outline';
import listFill from '@iconify/icons-eva/list-fill'
import homeOutline from '@iconify/icons-eva/home-outline';
// import peopleFill from '@iconify/icons-eva/peopleFill'

import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline'
import settingsOutline from '@iconify/icons-eva/settings-outline'
import gridOutline from '@iconify/icons-eva/grid-outline'
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Statistici',
    path: '/dashboard/app',
    icon: getIcon(pieChartOutline)
  },
  {
    title: 'Restaurant',
    path: '/dashboard/edit-restaurant',
    icon: getIcon(homeOutline)
  },
  {
    title: 'utilizatori',
    path: '/dashboard/user',
    icon: getIcon(peopleOutline)
  },
  {
    title: 'mese',
    path: '/dashboard/tables',
    icon: getIcon(gridOutline)
  },
  // {
  //   title: 'comenzi mese',
  //   path: '/dashboard/table-orders',
  //   icon: getIcon(listFill)
  // },
  {
    title: 'comenzi',
    path: '/dashboard/orders',
    icon: getIcon(fileTextOutline)
  },
  {
    title: 'categorii',
    path: '/dashboard/categories',
    icon: getIcon(listFill)
  },
  {
    title: 'produse',
    path: '/dashboard/products',
    icon: getIcon(shoppingCartOutline)
  }
  ,
  {
    title: 'Setari',
    path: '/dashboard/features',
    icon: getIcon(settingsOutline)
  }
  // ,
  // {
  //   title: 'Manage Features',
  //   path: '/dashboard/manage-features',
  //   // icon: getIcon(options2Outline)
  //   icon: getIcon(settingsOutline)
  // }
  // ,
  // {
  //   title: 'tags',
  //   path: '/dashboard/taggs',
  //   icon: getIcon(colorPaletteOutline)
  // }
  // ,

  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // }
  // ,
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  
  // ,
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
