import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {backendUrl} from '../../../index'
import useFetch from 'use-http'

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

// ----------------------------------------------------------------------

export default function CatMoreMenu({setUpdated, categoryId, status, deliveryOnly, quickCategory}) {

    async function handleDelete() {
      await fetch(backendUrl + `/categories/${categoryId}/soft-delete`,  {method: 'PUT', headers: {
        'Content-Type': 'application/json'
      }})
  
      setUpdated(Date.now())
      setIsOpen(false)
    }

  async function handlePublish() {
    await fetch(backendUrl + `/categories/${categoryId}/publish`,  {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }})

    setUpdated(Date.now())
    setIsOpen(false)
  }

  async function handleDeliveryOnly() {
    await fetch(backendUrl + `/categories/${categoryId}/deliveryOnly`,  {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }})

    setUpdated(Date.now())
    setIsOpen(false)
  }

  async function handleNotDeliveryOnly() {
    await fetch(backendUrl + `/categories/${categoryId}/notDeliveryOnly`,  {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }})

    setUpdated(Date.now())
    setIsOpen(false)
  }

  async function handleQuickCategory() {
    await fetch(backendUrl + `/categories/${categoryId}/quickCategory`,  {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }})

    setUpdated(Date.now())
    setIsOpen(false)
  }

  async function handleNotQuickCategory() {
    await fetch(backendUrl + `/categories/${categoryId}/notQuickCategory`,  {method: 'PUT', headers: {
      'Content-Type': 'application/json'
    }})
    setUpdated(Date.now())
    setIsOpen(false)
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {status ? 
          <MenuItem onClick={handleDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={eyeOffFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ascunde" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        :
          <MenuItem onClick={handlePublish} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={eyeFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Publica" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }

        {deliveryOnly ? 
          <MenuItem onClick={handleDeliveryOnly} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon="ic:outline-delivery-dining" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delivery Only" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        :
          <MenuItem onClick={handleNotDeliveryOnly} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon="ic:outline-table-restaurant" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Not Delivery Only" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
        
        {quickCategory ? 
          <MenuItem onClick={handleNotQuickCategory} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon="ant-design:expand-alt-outlined" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Extinde" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        :
          <MenuItem onClick={handleQuickCategory} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon="eva:collapse-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Restrange" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }  
        

      </Menu>
    </>
  );
}
