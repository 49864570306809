import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
// material
import {
  useNavigate
} from "react-router-dom";
import { Container, Stack, Typography } from '@mui/material';
import EditRestaurantForm  from '../components/_dashboard/restaurants/EditRestaurantForm';
import EditProfileForm  from '../components/_dashboard/user/EditProfileForm';

// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Profile() {

  return (
    <Page title="Dashboard: Products | Reach-UI">
      <Container>
       
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Modifica profil
          </Typography>
        </Stack>

        <EditProfileForm />
      </Container>
    </Page>
  );
}
