import { useRef, useState } from 'react';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import {
    Button
  } from '@mui/material';

import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';

export default function AddRestaurantButton() {
    return <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/add-restaurant"
                startIcon={<Icon icon={plusFill} />}
            >
                Adauga restaurant
            </Button>
}
