import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack,Avatar, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http'
import { backendUrl } from '../../../index'
import {useContext} from '../../../context/general/context'
import {logout} from '../../../context/general/actions'
// ----------------------------------------------------------------------

export default function EditProfileForm() {
    const {get, post, put, response, loading, error } = useFetch(backendUrl)
    const navigate = useNavigate();

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('FirstName required'),
        lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('LastName required')
    });

    const {state, dispatch} = useContext()
    console.log("state.userDetails")
    console.log(state.userDetails)

    const formik = useFormik({
        initialValues: {
            firstName: state.userDetails.firstName,
            lastName: state.userDetails.lastName
        },
        enableReinitialize: true,
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {

            await put(`users/${state.userDetails.sub}`, {...values})
            dispatch({ type: "GLOBAL_UPDATE"})
            logout(dispatch)
        }
    });
    

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    
                    <TextField
                        fullWidth
                        label="FirstName"
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                    />
                    
                    <TextField
                        fullWidth
                        label="LastName"
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                    />
                    
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                       

                    </Stack>
               
                    <LoadingButton
                        disabled={!state.currentRestaurantId}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Salveaza
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
